import { ActionTree } from 'vuex'

import { RootState } from '../types'
import { CartState } from './types'

import {
  cartItemIsAnUpsellingReference,
  extractUpsellItems,
  selectCurrentUpsellingGroup,
} from './upsell'

import api from '@/services'

import Cart from '@/entities/cart/Cart'
import CartItem from '@/entities/cart/CartItem'
import Attribute from '@/entities/cart/Attribute'

import { LS_CHECKOUT_ID } from '@/const'

import ShopifyBase64 from '@/utils/shopifyBase64'

interface IUpdateItemDeliveryArgs {
  products: {
    id: string
    metafield: {
      value: string
    }
  }[]
  getDeliveryText: Function
}

export const actions: ActionTree<CartState, RootState> = {
  getCheckout({ dispatch, commit }, id: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit('LOADING', true)
      api.checkoutService
        .fetch(id, this.$i18n.locale)
        .then((checkout) => {
          if (checkout && checkout.isCompleted) {
            localStorage.removeItem(LS_CHECKOUT_ID)
            commit('CART', new Cart({}))
          } else if (checkout) {
            commit('CART', checkout)
            dispatch('updateUpsellItems')
          }

          resolve()
        })
        .catch((err: any) => {
          console.error(err)
          commit('ERROR', err)
          reject(err)
        })
        .finally(() => {
          commit('LOADING', false)
        })
    })
  },
  addItems({ dispatch, state, commit }, items: CartItem[]): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit('LOADING', true)

      if (!state.cart.checkoutId) {
        return api.checkoutService
          .createCheckout(
            {
              lineItems: items.map((item) => item.toCheckoutLineItemInput()),
            },
            this.$i18n.locale
          )
          .then((cart) => {
            if (cart) {
              if (cart.checkoutId) {
                localStorage.setItem(LS_CHECKOUT_ID, cart.checkoutId)
              }

              commit('CART', cart)

              dispatch('updateUpsellItems')

              resolve()
            }
          })
          .catch((err: any) => {
            commit('ERROR', err)
            reject(err)
          })
          .finally(() => {
            commit('LOADING', false)
          })
      }

      // For each item check if already in checkout
      const pp: Promise<Cart>[] = []
      const itemsToAdd: CartItem[] = []
      items.forEach((item) => {
        const itemIndex = state.cart.items.findIndex(
          (i) => i.variantId === item.variantId
        )

        if (itemIndex > -1) {
          if (state.cart.checkoutId) {
            pp.push(
              api.checkoutService.updateLineItems(
                state.cart.checkoutId,
                [
                  {
                    id: state.cart.items[itemIndex].lineId,
                    variantId: state.cart.items[itemIndex].variantId,
                    quantity: state.cart.items[itemIndex].quantity + 1,
                  },
                ],
                this.$i18n.locale
              )
            )
          }
        } else {
          itemsToAdd.push(item)
        }
      })

      return Promise.all(pp)
        .then((_) => {
          if (state.cart.checkoutId) {
            return api.checkoutService
              .addLineItems(
                state.cart.checkoutId,
                itemsToAdd.map((item) => item.toCheckoutLineItemInput()),
                this.$i18n.locale
              )
              .then((cart) => {
                if (cart) {
                  commit('CART', cart)
                }

                dispatch('updateUpsellItems')
                resolve()
              })
              .catch((err: any) => {
                commit('ERROR', err)
                reject(err)
              })
              .finally(() => {
                commit('LOADING', false)
              })
          }
        })
        .catch((err: any) => {
          commit('ERROR', err)
          reject(err)
        })
    })
  },
  updateItem(
    { dispatch, state, commit },
    item: { id: string; quantity: number; variantId: string }
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (state.cart.checkoutId) {
        return api.checkoutService
          .updateLineItems(state.cart.checkoutId, [item], this.$i18n.locale)
          .then((cart) => {
            if (cart) {
              commit('CART', cart)
            }

            dispatch('updateUpsellItems')

            resolve()
          })
          .catch((err: any) => {
            console.error(err)
            commit('ERROR', err)
            reject(err)
          })
          .finally(() => {
            commit('LOADING', false)
          })
      }

      resolve()
    })
  },
  updateCartItemDelivery({ state, commit }, args: IUpdateItemDeliveryArgs) {
    return new Promise<void>((resolve, reject) => {
      const cartItemsToUpdate: Object[] = []
      for (const d of args.products) {
        const id = ShopifyBase64.getId(d.id)
        const deliveryDate = new Date(d.metafield.value)

        const itemsToUpdate = state.cart.items.filter(
          (i: CartItem) => i.productId === id
        )

        for (const itemToUpdate of itemsToUpdate) {
          if (itemToUpdate && !isNaN(deliveryDate.getTime())) {
            const attributeDeliveryIndex =
              itemToUpdate.customAttributes.findIndex(
                (c: Attribute) => c.key === '_delivery'
              )
            const attributeDeliveryDateIndex =
              itemToUpdate.customAttributes.findIndex(
                (c: Attribute) => c.key === '_delivery_date'
              )

            if (
              !itemToUpdate.customAttributes[attributeDeliveryIndex] ||
              !itemToUpdate.customAttributes[attributeDeliveryDateIndex] ||
              deliveryDate.toJSON() !==
                itemToUpdate.customAttributes[attributeDeliveryDateIndex].value
            ) {
              const customAttributes = itemToUpdate.customAttributes

              if (customAttributes[attributeDeliveryIndex]) {
                customAttributes[attributeDeliveryIndex].value =
                  args.getDeliveryText(deliveryDate)
              } else {
                customAttributes.push({
                  key: '_delivery',
                  value: args.getDeliveryText(deliveryDate),
                })
              }

              if (customAttributes[attributeDeliveryDateIndex]) {
                customAttributes[attributeDeliveryDateIndex].value =
                  deliveryDate.toJSON()
              } else {
                customAttributes.push({
                  key: '_delivery_date',
                  value: deliveryDate.toJSON(),
                })
              }

              cartItemsToUpdate.push({
                id: itemToUpdate.lineId,
                customAttributes,
              })
            }
          }
        }
      }

      if (cartItemsToUpdate.length > 0 && state.cart.checkoutId) {
        return api.checkoutService
          .updateLineItems(
            state.cart.checkoutId,
            cartItemsToUpdate,
            this.$i18n.locale
          )
          .then((cart) => {
            if (cart) {
              commit('CART', cart)
            }

            resolve()
          })
          .catch((err: any) => {
            console.error(err)
            commit('ERROR', err)
            reject(err)
          })
      }

      resolve()
    })
  },

  updateUpsell({ state, commit }) {
    const upsell: any = {
      has:
        state.cart.items.some((i) =>
          cartItemIsAnUpsellingReference(state.upsell.groups, i)
        ) || state.upsell.groups.some((g) => g.canCompleteList),
    }

    if (upsell.has) {
      upsell.group = selectCurrentUpsellingGroup(
        state.cart,
        state.upsell.groups,
        this.app.router?.currentRoute.params.context as 'h' | 'f'
      )

      if (upsell.group) {
        upsell.list = extractUpsellItems(
          state.cart,
          state.upsell.groups,
          upsell.group,
          this.app.router?.currentRoute.params.context as 'h' | 'f'
        )
      }
    }

    commit('SET_UPSELL', upsell)
  },
  updateUpsellItems({ state, commit, dispatch }) {
    dispatch('updateUpsell')
    if (state.upsell.has) {
      const itemsToLoad = state.upsell.list
        .filter((i: any) => state.upsell.items[i.id] === undefined)
        .map((i: any) => i.id)

      if (itemsToLoad.length) {
        commit('LOADING_UPSELLING', true)

        api.productService
          .getShopifyProductsForCartItem(itemsToLoad, this.$i18n.locale)
          .then((items) => {
            for (const item of items) {
              commit('ADD_UPSELL_ITEM', {
                id: ShopifyBase64.getId(item.id),
                item,
              })
            }
          })
          .catch((err: any) => {
            console.error(err)
            commit('ERROR', err)
          })
          .finally(() => {
            commit('LOADING_UPSELLING', false)
          })
      }
    }
  },
  addAttribute({ state, commit }, attribute: { key: string; value: string }) {
    if (state.cart.checkoutId) {
      return api.checkoutService
        .addAttributes(state.cart.checkoutId, attribute, this.$i18n.locale)
        .then((checkout) => {
          commit(
            'SET_CHECKOUT_ATTRIBUTES',
            checkout.customAttributes.map(
              (a: { key: string; value: string }) => new Attribute(a)
            )
          )
        })
    }
  },
}
