export default class UserSubscriptions {
  readonly id: string
  public readonly email: string
  public subscriptions: Map<string, boolean> = new Map()

  constructor(data: any, fromJson: boolean = false) {
    this.id = data.id || null
    this.email = data.email || null
    if (data.subscriptions) {
      if (fromJson) {
        this.subscriptions = data.subscriptions
      } else {
        for (const [key, value] of Object.entries(data.subscriptions)) {
          this.subscriptions.set(key, value as boolean)
        }
      }
    }
  }

  public isSubscribed(productSlug: string) {
    return (
      this.subscriptions.has(productSlug) &&
      this.subscriptions.get(productSlug) === true
    )
  }

  public subscribe(productSlug: string) {
    this.subscriptions.set(productSlug, true)
  }

  public unsubscribe(productSlug: string) {
    this.subscriptions.set(productSlug, false)
  }

  public toJSON() {
    return Object.assign({}, this)
  }

  static fromJSON(data: any): UserSubscriptions {
    return new UserSubscriptions(data, true)
  }
}

export interface ProductData {
  productSlug: string
  productName: string
  productId: string
  productImage: string
  location: string
}
