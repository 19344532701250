import Vue from 'vue'
import { ActionTree } from 'vuex'

import { RootState } from '../types'
import { SubscriptionState } from './types'

import UserSubscriptions, { ProductData } from '@/entities/UserSubscriptions'
import api from '@/services'
import { COOKIE_BIS_KEY } from '@/const'

export const actions: ActionTree<SubscriptionState, RootState> = {
  setGuestEmail({ commit }, email: string): Promise<void> {
    return new Promise<void>((resolve) => {
      const userSubcriptions = new UserSubscriptions({ email })
      commit('USER_SUBSCRIPTIONS', userSubcriptions)
      resolve()
    })
  },
  getUserSubscriptions({ commit }, email: string): Promise<UserSubscriptions> {
    return new Promise<UserSubscriptions>((resolve, reject) => {
      commit('LOADING', true)
      api.notificationCenterService
        .getProductsSubscriptions(email)
        .then((userSubcriptions: UserSubscriptions) => {
          commit('USER_SUBSCRIPTIONS', userSubcriptions)
          resolve(userSubcriptions)
        })
        .catch((err: any) => {
          commit('ERRORS', [err])
          reject(err)
        })
        .finally(() => {
          commit('LOADING', false)
        })
    })
  },
  subscribe({ state, commit }, product: ProductData): Promise<void> {
    if (state.userSubcriptions.email) {
      return new Promise<void>((resolve, reject) => {
        commit('ON_FETCH', true)
        api.notificationCenterService
          .subscribeToProduct(
            state.userSubcriptions.email,
            product,
            this.$i18n.locale,
            this.$router.currentRoute.params.context
          )
          .then(() => {
            const userSubcriptions = state.userSubcriptions
            userSubcriptions.subscribe(product.productSlug)
            commit('USER_SUBSCRIPTIONS', userSubcriptions)
            resolve()
          })
          .catch((err: any) => {
            commit('ERRORS', err)
            reject(err)
          })
          .finally(() => {
            commit('ON_FETCH', false)
          })
      })
    }

    return Promise.reject(new Error('No email detected for subscription'))
  },
  unsubscribe({ state, commit }, product: ProductData): Promise<void> {
    if (state.userSubcriptions.email) {
      return new Promise<void>((resolve, reject) => {
        commit('ON_FETCH', true)
        api.notificationCenterService
          .unsubscribeToProduct(state.userSubcriptions.email, product)
          .then(() => {
            const userSubcriptions = state.userSubcriptions
            userSubcriptions.unsubscribe(product.productSlug)
            commit('USER_SUBSCRIPTIONS', userSubcriptions)
            resolve()
          })
          .catch((err: any) => {
            commit('ERRORS', err)
            reject(err)
          })
          .finally(() => {
            commit('ON_FETCH', false)
          })
      })
    }

    return Promise.reject(new Error('No email detected for unsubscription'))
  },
  hasConnectedEmail({ commit }): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (Vue.prototype.$cookies.isKey(COOKIE_BIS_KEY)) {
        const email = Buffer.from(
          Vue.prototype.$cookies.get(COOKIE_BIS_KEY),
          'base64'
        )

        if (email) {
          commit('CONNECTED_EMAIL', email.toString())
          resolve(true)
          return
        }

        Vue.prototype.$cookies.remove(COOKIE_BIS_KEY)
      }
      commit('CONNECTED_EMAIL', '')
      resolve(false)
    })
  },
  setConnectedEmail({ dispatch, commit }, email: string): Promise<void> {
    return new Promise<void>((resolve) => {
      const emailBuffer = Buffer.from(email, 'utf-8')
      Vue.prototype.$cookies.set(
        COOKIE_BIS_KEY,
        emailBuffer.toString('base64'),
        { expires: '1y' }
      )
      commit('CONNECTED_EMAIL', email)
      dispatch('getUserSubscriptions', email)
      resolve()
    })
  },
  disconnectEmail({ commit }): Promise<void> {
    return new Promise<void>((resolve) => {
      Vue.prototype.$cookies.remove(COOKIE_BIS_KEY)
      commit('CONNECTED_EMAIL', '')
      commit('USER_SUBSCRIPTIONS', new UserSubscriptions({}))
      resolve()
    })
  },
}
