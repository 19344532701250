import { Plugin, Context } from '@nuxt/types'
import * as prismic from '@prismicio/client'

import { linkResolverWithContext } from './link-resolver'

function getPreviewCookie(): any {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${prismic.cookie.preview}=`)
  if (parts.length !== 2) return null
  let cookie: string | null | undefined = parts.pop()?.split(';').shift()
  if (!cookie) return null
  try {
    cookie = JSON.parse(decodeURIComponent(cookie))
  } catch (e) {
    cookie = null
  }
  return cookie
}

export class PrismicClient {
  ctx: Context
  repo: string = process.env.PRISMIC_ENDPOINT || 'asphalte'
  endpoint: string
  client: prismic.Client
  isPreview: boolean = false

  constructor(ctx: Context) {
    this.ctx = ctx

    this.endpoint = prismic.getEndpoint(this.repo)

    this.client = prismic.createClient(this.endpoint, {
      fetch,
      routes: [
        {
          type: 'accueil',
          path: '/',
        },
        {
          type: 'product',
          path: '/products/:uid',
        },
        {
          type: 'questionnaire',
          path: '/questionnaires/:uid',
        },
        {
          type: 'collection',
          path: '/collections/:uid',
        },
        {
          type: 'page',
          path: '/pages/:uid',
        },
        {
          type: 'blog',
          path: '/blogs/:uid',
        },
        {
          type: 'article',
          path: '/blogs/news/:uid',
        },
      ],
    })

    this.client.enableAutoPreviews()
  }

  addPrismicScript() {
    if (document.getElementById('prismic-script')) {
      return
    }

    const script = document.createElement('script')
    script.src = `//static.cdn.prismic.io/prismic.js?repo=${this.repo}&new=true`
    script.id = 'prismic-script'
    document.body.appendChild(script)
  }

  async preview() {
    this.client.enableAutoPreviewsFromReq(this.ctx.req)
    const previewURL = await this.client.resolvePreviewURL({
      linkResolver: linkResolverWithContext,
      defaultURL: '/',
    })

    this.addPrismicScript()
    this.ctx.app.router?.push({ path: previewURL, query: { preview: 'true' } })
  }

  loadPrismic() {
    if (process.client) {
      window.addEventListener('prismicPreviewUpdate', async (event) => {
        if (
          this.ctx.app &&
          'refresh' in this.ctx.app &&
          typeof this.ctx.app.refresh === 'function'
        ) {
          event.preventDefault()
          if (
            this.ctx.app.$store &&
            this.ctx.app.$store._actions.nuxtServerInit
          ) {
            await this.ctx.app.$store.dispatch(
              'nuxtServerInit',
              this.ctx.app.$options.context
            )
          }
          await this.ctx.app.refresh()
        }
      })

      const previewCookie = getPreviewCookie()

      this.isPreview =
        previewCookie &&
        previewCookie[`${this.repo}.prismic.io`] &&
        previewCookie[`${this.repo}.prismic.io`].preview

      if (this.isPreview) {
        this.addPrismicScript()
        if (this.ctx.enablePreview) {
          this.ctx.enablePreview()
        }
      }
    }
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $prismic: PrismicClient
  }

  interface ComponentCustomProperties {
    $prismic: PrismicClient
  }
}

declare module '@nuxt/types' {
  interface Context {
    $prismic: PrismicClient
  }
}

declare module 'vuex/types/index' {
  // this.$myInjectedFunction inside Vuex stores
  interface Store<S> {
    $prismic: PrismicClient
  }
}

const PrismicClientPlugin: Plugin = (context, inject) => {
  const prismicClient = new PrismicClient(context)
  inject('prismic', prismicClient)

  prismicClient.loadPrismic()
}

export default PrismicClientPlugin
