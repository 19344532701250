export default async function ({ route, $axios, $track, store, $sentry }) {
  if (process.client) {
    $track.updateConsent()

    if (store.getters['auth/authenticated']) {
      const customer = store.getters['auth/customer']
      if (customer) {
        $track.setUser(customer)
      }
    }
  }

  if (
    process.client &&
    process.env.NODE_ENV === 'production' &&
    process.env.BUILD_ENV === 'production'
  ) {
    try {
      const currentNetlifyBuildID = localStorage.getItem('netlifyBuildID')

      if (currentNetlifyBuildID === null) {
        return
      }

      const getBuildIdFromFunction = await $axios.get(
        `${process.env.BASE_URL}/.netlify/functions/getBuildId`
      )
      const remoteNetlifyBuildID = getBuildIdFromFunction?.data?.buildId
      if (
        remoteNetlifyBuildID !== undefined &&
        currentNetlifyBuildID !== remoteNetlifyBuildID
      ) {
        localStorage.removeItem('netlifyBuildID')
        window.location.assign(route.fullPath)
      }
    } catch (e) {
      $sentry.captureException(e)
    }
  }
}
