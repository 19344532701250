import axios from 'axios'
import { Mapper } from '@/entities/api/Page'
import Cart from '@/entities/cart/Cart'
import CartItem from '~/entities/cart/CartItem'

class CartService {
  private baseUrl: string

  constructor() {
    if (!process.env.SHOPIFY_URL) {
      throw new Error('Missing env var SHOPIFY_URL')
    }

    this.baseUrl = process.env.SHOPIFY_URL
  }

  getCart(): Promise<Cart> {
    const query = new URL(`${this.baseUrl}/cart.js`)

    return new Promise<Cart>((resolve, reject) => {
      axios.get(query.toString()).then(
        (r) => {
          if (r && r.status === 200) {
            const res = mapper.map(r.data)
            return resolve(res)
          }
          reject(r)
        },
        (r) => {
          console.error(r)
          // sentry.sendErrorToSentry(r.message, 'subscriptions', 'get')
          reject(r.message)
        }
      )
    })
  }

  addItem(item: CartItem): Promise<void> {
    const url = new URL(`${this.baseUrl}/cart/add.js`)

    const payload = { items: [item.toString()] }
    const headers = { headers: { 'Access-Control-Allow-Origin': '*' } }

    return new Promise<void>((resolve, reject) => {
      axios.post(url.toString(), payload, headers).then(
        (r) => {
          if (r && r.status === 200) {
            return resolve()
          }
          reject(r)
        },
        (r) => {
          console.error(r)
          // sentry.sendErrorToSentry(r.message, 'subscriptions', 'get')
          reject(r.message)
        }
      )
    })
  }

  updateQuantities(items: CartItem[]): Promise<void> {
    const url = new URL(`${this.baseUrl}/cart/update.js`)

    // let updates: Map<string, number>
    const updates = items.map((item: CartItem) => {
      return { key: item.variantId, value: item.quantity }
    })

    const payload = { updates }

    return new Promise<void>((resolve, reject) => {
      axios.post(url.toString(), payload).then(
        (r) => {
          if (r && r.status === 200) {
            return resolve()
          }
          reject(r)
        },
        (r) => {
          console.error(r)
          // sentry.sendErrorToSentry(r.message, 'subscriptions', 'get')
          reject(r.message)
        }
      )
    })
  }
}

class CartMapper implements Mapper<Cart> {
  map(obj: any): Cart {
    return new Cart(obj)
  }
}
const mapper = new CartMapper()

export default new CartService()
