import axios from 'axios'

import Testimonial, { TestimonialInterface } from '@/entities/Testimonials'
import Query, { QueryParametersInterface } from '@/entities/api/Query'
import Pagination, { PaginationData } from '@/entities/api/Pagination'

export interface TestimonialsData {
  avis: Testimonial[]
  currentPage: 0
  totalItems: 0
  totalPages: 0
}

export interface TestimonialsCount {
  1: number
  2: number
  3: number
  4: number
  5: number
}
export class TestimonialService {
  baseURL: string
  private endpoint = {
    avis: '/avis',
    count: '/avis/count',
  }

  constructor() {
    if (!process.env.AVIS_CLIENT_URL) {
      throw new Error('Missing env var AVIS_CLIENT_URL')
    }
    this.baseURL = process.env.AVIS_CLIENT_URL
  }

  async get(p: Pagination<Testimonial, TestimonialInterface>) {
    const q = new Query(this.baseURL, this.endpoint.avis, {
      ...p.toQueryCriteria(),
      hasComment: true,
    })

    return await axios
      .get(q.toQuery())
      .then((data) => {
        if (data && data.status === 200) {
          p.update(
            data.data as PaginationData<TestimonialInterface>,
            (r: TestimonialInterface) => {
              return new Testimonial(r)
            }
          )
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  async count(p: QueryParametersInterface): Promise<TestimonialsCount | void> {
    const q = new Query(this.baseURL, this.endpoint.count, p)

    return await axios
      .get(q.toQuery())
      .then((data) => {
        if (data && data.status === 200) {
          return data.data as TestimonialsCount
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }
}

export default new TestimonialService()
