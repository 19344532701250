<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    focusable="false"
    :class="iconClass"
  >
    <use :xlink:href="`/icons.svg#${name}`"></use>
  </svg>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: { type: String, required: true },
    full: { type: Boolean, default: false },
  },
  computed: {
    iconClass() {
      const cc = ['icon', `icon--${this.name}`]

      if (this.full) {
        cc.push('icon--full')
      }

      return cc
    },
  },
}
</script>

<style lang="scss">
.icon {
  display: inline-block;
  fill: currentcolor;
  width: rem(20px);
  height: rem(20px);

  &--user,
  &--cross {
    fill: none;
    stroke: currentcolor;
  }

  &--before-text {
    width: calc(var(--spacing) * 2);
  }

  &--info {
    width: rem(14px);
    height: rem(14px);
  }

  &--arrow-down {
    width: rem(12px);
    height: rem(16px);
  }

  &--arrow-right {
    width: rem(16px);
    height: rem(12px);
  }

  &--arrow-up,
  &--arrow-left {
    width: rem(16px);
    height: rem(16px);

    &.icon {
      fill: none;
      stroke: currentcolor;
    }
  }

  &--bell {
    fill: transparent;
    stroke: currentcolor;

    &.icon--full,
    .icon--full & {
      fill: currentcolor;
    }
  }

  &--bell,
  &--zoom-plus,
  &--check,
  &--download {
    width: rem(24px);
    height: rem(24px);
  }

  &--cart,
  &--cart-full {
    width: rem(20px);
    height: rem(22px);
  }

  &--cross {
    width: rem(13px);
    height: rem(13px);
  }

  &--lock {
    width: rem(40px);
    height: rem(50px);
  }

  &--asphalte-avatar {
    width: rem(36px);
    height: rem(36px);
  }

  &--certified {
    width: rem(14px);
    height: rem(14px);
  }

  &--calendar {
    width: rem(14px);
    height: rem(12px);
  }

  &--quote-start {
    width: rem(36px);
    height: rem(27px);
  }

  &--quote-end {
    width: rem(35px);
    height: rem(27px);
  }

  &--estimated-time {
    width: rem(15px);
    height: rem(16px);
  }

  &--plan-de-collection {
    width: rem(81px);
    height: rem(83px);
  }

  &--satisfaction {
    width: rem(75px);
    height: rem(74px);
  }

  &--achat-matiere {
    width: rem(75px);
    height: rem(75px);
  }

  &--focus-group {
    width: rem(125px);
    height: rem(53px);
  }

  &--development {
    width: rem(95px);
    height: rem(59px);
  }

  &--brand,
  &--autres-sujets {
    width: rem(26px);
    height: rem(35px);
  }

  &--box {
    width: rem(19px);
    height: rem(21px);
  }

  &--play {
    width: rem(29px);
    height: rem(29px);
    fill: none !important;
    stroke: currentcolor;
  }

  &--carret-up,
  &--carret-down {
    width: rem(12px);
    height: rem(8px);
  }

  &--carret-left,
  &--carret-right {
    width: rem(8px);
    height: rem(12px);
  }

  &--adresses,
  &--alertes,
  &--cagnotte,
  &--commandes,
  &--compte,
  &--vestiaire,
  &--care-guide {
    width: rem(48px);
    height: rem(48px);

    @include mq($from: tablet) {
      width: rem(62px);
      height: rem(62px);
    }
  }

  &--info-circle,
  &--plus-circle {
    width: rem(24px);
    height: rem(24px);
  }

  &--b-corp {
    width: rem(80px);
    height: rem(100px);
  }
}
</style>
